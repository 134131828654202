@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --kk-base-blue: "#081359";
}

.header-text,
.testimonial-name-text {
  font-family: "Poppins", sans-serif;
  color: #081359;
  text-transform: uppercase;
}

.content-text {
  font-family: "Nunito Sans", sans-serif;
  color: #081359;
}

.footer-text-white,
.description-text,
.description-text-header {
  font-family: "Poppins", sans-serif;
  color: white;
}

.description-text {
  font-family: "Nunito Sans", sans-serif;
}

.testimonial-message-text,
.header-menu-text {
  font-family: "Raleway", sans-serif;
  color: #081359;
  white-space: pre-line;
}

.header-menu-spacer {
  border-bottom: 2px solid #081359;
}

.testimonial-name-text {
  letter-spacing: 3px;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

.content-link {
  color: #478ac9;
  transition: all 300s ease-in-out;
}

.content-link:hover {
  color: #f1592a;
}

.footer-link {
  color: #9099d6;
}

.footer-link:visited {
  color: #4256db;
}

.footer-link:hover {
  color: #c9cff5;
}

.footer-background {
  background: #081359;
}
/* #f1592a for logo */

/* "join the mailing list" button on FAQ page - in-person questions*/

.joinList {
  position: relative;
  outline: none;
  background: none;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 12px;
  color: #324da1;
  font-size: 1.4rem;
  font-weight: 600;
  z-index: 1;
  text-align: center;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .joinList {
    font-size: 1rem;
  }
}

.joinList:hover {
  color: #f3f4f6;
}

.joinList::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: #324da1;
  opacity: 0.9;
  border-radius: 12px;
  transition: transform 0.5s;
  transform-origin: bottom right;
  transform: scale(0);
}

.joinList:hover::before {
  transition: transform 0.5s;
  transform-origin: top left;
  transform: scale(1);
}

.joinList::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: transparent;
  border-radius: 12px;
  border: 2px solid #035b96;
  transition: transform 0.5s;
  transform-origin: top left;
  transform: scale(1);
}

.joinList:hover::after {
  transition: transform 0.5s;
  transform-origin: bottom right;
  transform: scale(0);
}

.titles {
  color: #035b96;
  font-weight: 600;
}

.swiper-slide-active > div {
  background: #324da1;
  color: #f3f4f6;
}

.swiper-wrapper {
  margin-bottom: 8vh;
}

.swiper-pagination-bullet-active {
  background: #324da1 !important;
}

.btn-dark-blue {
  color: #4889c9 !important;
  background-color: #212529;
  border-color: #212529;
}

.font-dark-black {
  color: #4889c9;
  font-size: 1.25rem;
  line-height: 1.75rem;
  padding-left: 3rem;
  padding-right: 8rem;
}
.Dropdown-control {
  cursor: pointer !important;
}

.Dropdown-arrow {
  margin-top: 5px !important;
}

.v-hidden {
  visibility: hidden;
}

.form-select {
  font-family: "Poppins", sans-serif;
}

/* styles for quantity picker in shopping cart component */
.quantity-picker {
  width: 100%;
  height: 100%;
  display: flex !important;
  align-items: center;
}

.quantity-modifier,
.quantity-display {
  width: 33% !important;
  font-size: 0.5rem !important;
}

.quantity-display {
  font-size: 0.8rem !important;
  padding: 0 !important;
}

.text-line-through {
  text-decoration: line-through;
  font-size: 0.8rem;
  color: #707070;
}

.faq-link {
  color: #324da1 !important;
  text-decoration: none;
  font-family: "Poppins", sans-serif;
}

.header-column-gap {
  column-gap: 1.5rem;
  flex-wrap: nowrap;
}

.dropdown-menu {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  border-radius: 5px;
  padding: 0.5rem;
  left: -7px;
}
.dropdown-item {
  margin: 0;
}

.dropdown-submenu{
  position: absolute;
  background-color: #f9f9f9;
  border-radius: 5px;
  padding: 0.5rem;
  left: -20px;
}

.arrow-down {
  border: solid #081359;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2px;
  transform: rotate(45deg);
  margin-left: 5px;
  position: relative;
  top: -3px;
}

.nav-item:hover .dropdown-menu {
  display: block;
}

.dropdown-menu-open {
  display: block !important;
}

.dropdown-close {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border: none;
  padding: 10px;
  cursor: pointer;
}

.dropdown-menuc {
  display: none;
  position: relative !important;
}

.dropdown-menu li {
  padding: 5px 10px;
}

.dropdown-menu li:hover {
  background-color: #f9f9f9;
}

.dropdown-menu li:first-child {
  border-radius: 4px 4px 0 0;
}

.dropdown-menu li:last-child {
  border-radius: 0 0 4px 4px;
}
