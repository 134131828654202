.active {
  opacity: 1 !important;
}
.displayed-desc-block {
  display: block !important;
}
.active-desc-title {
  color: black !important;
  font-weight: bold;
}
p,
input,
label,
button {
  font-family: "Poppins", sans-serif;
}
.form-select {
  font-family: "Poppins", sans-serif;
}
b {
  font-weight: 600;
}

.v-hidden {
  display: none !important;
}

.active-title {
  color: black !important;
}
.displayed-desc-block {
  display: block;
}

.lightbox-active {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

#lightbox img {
  max-width: 90%;
  max-height: 80%;
  padding: 4px;
  background-color: black;
  border: 2px solid white;
}
