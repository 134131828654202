.u-section-1 {
  background-image: none;
}

.u-section-1 .u-sheet-1 {
  min-height: 812px;
}

.u-section-1 .u-shape-1 {
  width: 432px;
  height: 557px;
  background-image: none;
  margin: 50px 0 0 auto;
}

.u-section-1 .u-shape-2 {
  width: 780px;
  height: 615px;
  background-image: none;
  margin: -607px auto 0 0;
}

.u-section-1 .u-group-1 {
  width: 536px;
  min-height: 488px;
  background-image: none;
  margin: -514px auto 0 45px;
}

.u-section-1 .u-container-layout-1 {
  padding: 0 40px 30px 0;
}

.u-section-1 .u-text-1 {
  font-weight: 700;
  font-size: 3rem;
  margin: 0;
}

.u-section-1 .u-text-2 {
  font-size: 1.125rem;
  line-height: 1.8;
  margin: 40px 0 0;
}

.u-section-1 .u-btn-1 {
  letter-spacing: 3px;
  font-size: 1.25rem;
  border-style: none;
  background-image: none;
  text-transform: uppercase;
  font-weight: 400;
  margin: 30px auto 0 0;
  padding: 10px 62px 10px 61px;
}

.u-section-1 .u-image-1 {
  width: 488px;
  height: 656px;
  margin: -488px 50px 0 auto;
}

@media (max-width: 1199px) {
  .u-section-1 .u-sheet-1 {
    min-height: 762px;
  }

  .u-section-1 .u-container-layout-1 {
    padding-right: 25px;
  }

  .u-section-1 .u-text-1 {
    font-size: 2.75rem;
  }
}

@media (max-width: 991px) {
  .u-section-1 .u-sheet-1 {
    min-height: 100vh;
  }

  .u-section-1 .u-shape-2 {
    width: 504px;
    height: 531px;
    margin-top: -523px;
  }

  .u-section-1 .u-group-1 {
    width: 417px;
    min-height: 505px;
    margin-top: -531px;
    margin-left: 40px;
  }

  .u-section-1 .u-container-layout-1 {
    padding-top: 17px;
  }

  .u-section-1 .u-image-1 {
    width: 308px;
    height: 518px;
    margin-top: -415px;
    margin-right: 18px;
    margin-bottom: 60px;
  }
}

@media (max-width: 767px) {
  .u-section-1 .u-container-layout-1 {
    padding-right: 10px;
  }
}

@media (max-width: 575px) {
  .u-section-1 .u-sheet-1 {
    min-height: 947px;
  }

  .u-section-1 .u-shape-1 {
    width: 185px;
    height: 364px;
    margin-top: 78px;
  }

  .u-section-1 .u-shape-2 {
    height: 341px;
    width: 218px;
    margin-top: -391px;
    margin-left: 18px;
  }

  .u-section-1 .u-group-1 {
    min-height: 431px;
    width: 322px;
    margin-top: 71px;
    margin-left: auto;
  }

  .u-section-1 .u-container-layout-1 {
    padding-top: 30px;
  }

  .u-section-1 .u-text-1 {
    font-size: 1.875rem;
    margin-right: 10px;
  }

  .u-section-1 .u-text-2 {
    width: auto;
    margin-right: 18px;
  }

  .u-section-1 .u-btn-1 {
    margin-top: 0;
    margin-left: auto;
  }

  .u-section-1 .u-image-1 {
    width: 247px;
    height: 318px;
    margin-top: -793px;
    margin-right: auto;
  }
}
.u-section-2 {
  background-image: none;
}

.u-section-2 .u-sheet-1 {
  min-height: 671px;
}

.u-section-2 .u-layout-wrap-1 {
  margin-bottom: 60px;
}

.u-section-2 .u-layout-cell-1 {
  min-height: 534px;
  background-image: none;
}

.u-section-2 .u-container-layout-1 {
  padding: 30px;
}

.u-section-2 .u-shape-1 {
  width: 354px;
  height: 15px;
  margin: 40px auto 0 0;
}

.u-section-2 .u-text-1 {
  font-style: normal;
  font-weight: 700;
  font-size: 2.25rem;
  margin: 40px 0 0;
}

.u-section-2 .u-text-2 {
  font-size: 1.25rem;
  margin: 30px 0 0;
}

.u-section-2 .u-layout-cell-2 {
  min-height: 608px;
  background-image: none;
}

.u-section-2 .u-container-layout-2 {
  padding: 30px;
}

.u-section-2 .u-text-3 {
  font-size: 1.75rem;
  margin: 164px 0 0;
}

.u-section-2 .u-text-4 {
  font-size: 1.25rem;
  margin: 49px 0 0;
}

.u-section-2 .u-btn-1 {
  letter-spacing: 3px;
  font-size: 1.25rem;
  border-style: none;
  background-image: none;
  text-transform: uppercase;
  margin: 30px auto 0 0;
  padding: 10px 62px 10px 61px;
}

@media (max-width: 1199px) {
  .u-section-2 .u-sheet-1 {
    min-height: 430px;
  }

  .u-section-2 .u-layout-wrap-1 {
    position: relative;
  }

  .u-section-2 .u-layout-cell-1 {
    min-height: 440px;
  }

  .u-section-2 .u-layout-cell-2 {
    min-height: 501px;
  }
}

@media (max-width: 991px) {
  .u-section-2 .u-sheet-1 {
    min-height: 353px;
  }

  .u-section-2 .u-layout-cell-1 {
    min-height: 100px;
  }

  .u-section-2 .u-shape-1 {
    width: 300px;
  }

  .u-section-2 .u-layout-cell-2 {
    min-height: 100px;
  }
}

@media (max-width: 767px) {
  .u-section-2 .u-sheet-1 {
    min-height: 580px;
  }

  .u-section-2 .u-container-layout-1 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-2 .u-container-layout-2 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-2 .u-text-4 {
    margin-top: 0;
  }
}

@media (max-width: 575px) {
  .u-section-2 .u-sheet-1 {
    min-height: 1241px;
  }

  .u-section-2 .u-layout-wrap-1 {
    margin-top: 1px;
    margin-bottom: 10px;
  }

  .u-section-2 .u-text-1 {
    font-size: 1.875rem;
  }

  .u-section-2 .u-layout-cell-2 {
    min-height: 468px;
  }

  .u-section-2 .u-container-layout-2 {
    padding-top: 0;
    padding-left: 9px;
    padding-right: 9px;
  }

  .u-section-2 .u-text-3 {
    width: auto;
    margin-top: 70px;
    margin-left: 1px;
    margin-right: 1px;
  }

  .u-section-2 .u-text-4 {
    width: auto;
    margin-left: 1px;
    margin-right: 1px;
  }

  .u-section-2 .u-btn-1 {
    margin-left: auto;
  }
}
.u-section-3 {
  background-image: url("../images/trh.jpg");
  background-position: 50% 50%;
}

.u-section-3 .u-sheet-1 {
  min-height: 599px;
}

.u-section-3 .u-layout-wrap-1 {
  width: 1140px;
  margin: 0 auto;
}

.u-section-3 .u-layout-cell-1 {
  min-height: 599px;
}

.u-section-3 .u-container-layout-1 {
  padding: 30px 0 0 40px;
}

.u-section-3 .u-image-1 {
  object-position: 100% 100%;
  height: 569px;
  width: 530px;
  margin: 0;
}

.u-section-3 .u-layout-cell-2 {
  min-height: 599px;
}

.u-section-3 .u-container-layout-2 {
  padding: 50px 40px;
}

.u-section-3 .u-text-1 {
  letter-spacing: normal;
  font-weight: 700;
  font-family: Raleway, sans-serif;
  text-transform: none;
  font-size: 3rem;
  margin: 139px 0 0;
}

.u-section-3 .u-text-2 {
  font-size: 1.125rem;
  font-style: italic;
  margin: 25px 0 0;
}

@media (max-width: 1199px) {
  .u-section-3 .u-sheet-1 {
    min-height: 389px;
  }

  .u-section-3 .u-layout-wrap-1 {
    width: 940px;
  }

  .u-section-3 .u-layout-cell-1 {
    background-position: 50% 100%;
    min-height: 560px;
  }

  .u-section-3 .u-container-layout-1 {
    padding-left: 0;
  }

  .u-section-3 .u-image-1 {
    height: 476px;
    width: 439px;
    margin-right: auto;
  }

  .u-section-3 .u-layout-cell-2 {
    min-height: 494px;
  }

  .u-section-3 .u-container-layout-2 {
    padding-left: 35px;
    padding-right: 35px;
  }

  .u-section-3 .u-text-1 {
    font-size: 2.75rem;
  }
}

@media (max-width: 991px) {
  .u-section-3 .u-sheet-1 {
    min-height: 273px;
  }

  .u-section-3 .u-layout-wrap-1 {
    width: 720px;
  }

  .u-section-3 .u-layout-cell-1 {
    background-position: 28.32% 50%;
    min-height: 509px;
  }

  .u-section-3 .u-image-1 {
    height: 391px;
    margin-right: initial;
    margin-left: initial;
    width: auto;
  }

  .u-section-3 .u-layout-cell-2 {
    min-height: 100px;
  }

  .u-section-3 .u-container-layout-2 {
    padding: 30px 25px;
  }

  .u-section-3 .u-text-1 {
    font-size: 2.25rem;
  }
}

@media (max-width: 767px) {
  .u-section-3 .u-sheet-1 {
    min-height: 603px;
  }

  .u-section-3 .u-layout-wrap-1 {
    width: 540px;
  }

  .u-section-3 .u-layout-cell-1 {
    min-height: 516px;
    order: 1;
  }

  .u-section-3 .u-image-1 {
    height: 511px;
    width: auto;
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-3 .u-layout-cell-2 {
    min-height: 278px;
    order: 0;
  }

  .u-section-3 .u-container-layout-2 {
    padding-top: 50px;
    padding-right: 50px;
    padding-left: 10px;
  }

  .u-section-3 .u-text-1 {
    font-size: 2.75rem;
    width: auto;
    margin-top: 21px;
  }
}

@media (max-width: 575px) {
  .u-section-3 .u-sheet-1 {
    min-height: 378px;
  }

  .u-section-3 .u-layout-wrap-1 {
    width: 340px;
  }

  .u-section-3 .u-layout-cell-1 {
    min-height: 325px;
  }

  .u-section-3 .u-image-1 {
    height: 322px;
    width: auto;
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-3 .u-layout-cell-2 {
    min-height: 237px;
  }

  .u-section-3 .u-container-layout-2 {
    padding-top: 0;
    padding-right: 20px;
  }

  .u-section-3 .u-text-1 {
    font-size: 1.875rem;
    margin-top: 47px;
    margin-right: -5px;
    margin-left: 5px;
  }

  .u-section-3 .u-text-2 {
    width: auto;
    margin-right: -5px;
    margin-left: 5px;
  }
}
.u-section-4 {
  background-image: none;
}

.u-section-4 .u-sheet-1 {
  min-height: 500px;
}

.u-section-4 .u-layout-wrap-1 {
  margin-top: 60px;
  margin-bottom: 60px;
}

.u-section-4 .u-layout-cell-1 {
  min-height: 534px;
  background-image: none;
}

.u-section-4 .u-container-layout-1 {
  padding: 30px;
}

.u-section-4 .u-text-1 {
  font-style: normal;
  font-weight: 700;
  font-size: 2.25rem;
  margin: 0;
}

.u-section-4 .u-text-2 {
  font-size: 1.25rem;
  margin: 47px 0 0;
}

.u-section-4 .u-layout-cell-2 {
  min-height: 534px;
  background-image: none;
}

.u-section-4 .u-container-layout-2 {
  padding: 30px;
}

.u-section-4 .u-shape-1 {
  width: 102px;
  height: 102px;
  background-image: none;
  margin: 103px auto 0 0;
}

.u-section-4 .u-text-3 {
  margin: 83px 0 0;
}

@media (max-width: 1199px) {
  .u-section-4 .u-sheet-1 {
    min-height: 430px;
  }

  .u-section-4 .u-layout-wrap-1 {
    position: relative;
  }

  .u-section-4 .u-layout-cell-1 {
    min-height: 440px;
  }

  .u-section-4 .u-layout-cell-2 {
    min-height: 440px;
  }
}

@media (max-width: 991px) {
  .u-section-4 .u-sheet-1 {
    min-height: 353px;
  }

  .u-section-4 .u-layout-cell-1 {
    min-height: 100px;
  }

  .u-section-4 .u-layout-cell-2 {
    min-height: 100px;
  }
}

@media (max-width: 767px) {
  .u-section-4 .u-sheet-1 {
    min-height: 580px;
  }

  .u-section-4 .u-container-layout-1 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-4 .u-layout-cell-2 {
    min-height: 369px;
  }

  .u-section-4 .u-container-layout-2 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-4 .u-shape-1 {
    margin-top: 25px;
    margin-right: 418px;
  }

  .u-section-4 .u-text-3 {
    width: auto;
    margin-top: 0;
  }
}

@media (max-width: 575px) {
  .u-section-4 .u-sheet-1 {
    min-height: 796px;
  }

  .u-section-4 .u-layout-wrap-1 {
    margin-bottom: 0;
  }

  .u-section-4 .u-layout-cell-1 {
    min-height: 285px;
  }

  .u-section-4 .u-text-1 {
    font-size: 1.875rem;
  }

  .u-section-4 .u-layout-cell-2 {
    min-height: 340px;
  }

  .u-section-4 .u-container-layout-2 {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .u-section-4 .u-shape-1 {
    margin-right: 218px;
    margin-left: 10px;
  }

  .u-section-4 .u-text-3 {
    margin-top: -51px;
    margin-left: 20px;
  }
}
.u-section-5 {
  background-image: none;
}

.u-section-5 .u-sheet-1 {
  min-height: 638px;
}

.u-section-5 .u-text-1 {
  width: 567px;
  font-size: 3rem;
  margin: 53px auto 0;
}

.u-section-5 .u-text-2 {
  font-weight: 700;
  width: 720px;
  margin: 20px auto 0;
}

.u-section-5 .u-layout-wrap-1 {
  width: 1120px;
  margin: 33px 0 51px auto;
}

.u-section-5 .u-layout-cell-1 {
  min-height: 447px;
  background-image: none;
}

.u-section-5 .u-container-layout-1 {
  padding: 20px;
}

.u-section-5 .u-text-3 {
  text-transform: none;
  font-weight: 700;
  font-size: 2.25rem;
  margin: 68px 77px 0 10px;
}

.u-section-5 .u-text-4 {
  background-image: none;
  margin: 0 0 0 10px;
}

.u-section-5 .u-line-1 {
  height: 10px;
  transform-origin: left center 0px;
  margin: 20px 10px 0;
}

.u-section-5 .u-text-5 {
  font-size: 1.25rem;
  text-align: left;
  margin: 37px 0 0 10px;
}

.u-section-5 .u-btn-1 {
  background-image: none;
  text-transform: uppercase;
  font-size: 1.125rem;
  letter-spacing: 2px;
  margin: 19px auto 0;
  padding: 6px 24px 7px 23px;
}

.u-section-5 .u-layout-cell-2 {
  min-height: 447px;
  background-image: none;
}

.u-section-5 .u-container-layout-2 {
  padding: 20px 30px;
}

.u-section-5 .u-text-6 {
  text-transform: none;
  font-weight: 700;
  font-size: 2.25rem;
  margin: 0 67px 0 0;
}

.u-section-5 .u-text-7 {
  margin: 0;
}

.u-section-5 .u-line-2 {
  height: 10px;
  transform-origin: left center 0px;
  margin-top: 20px;
  margin-bottom: 0;
}

.u-section-5 .u-text-8 {
  font-size: 1.25rem;
  margin: 35px 0 0;
}

@media (max-width: 1199px) {
  .u-section-5 .u-sheet-1 {
    min-height: 551px;
  }

  .u-section-5 .u-layout-wrap-1 {
    width: 940px;
  }

  .u-section-5 .u-layout-cell-1 {
    min-height: 375px;
  }

  .u-section-5 .u-text-3 {
    margin-right: 0;
    margin-left: 0;
  }

  .u-section-5 .u-text-4 {
    margin-left: 0;
  }

  .u-section-5 .u-line-1 {
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-5 .u-text-5 {
    margin-left: 0;
  }

  .u-section-5 .u-layout-cell-2 {
    min-height: 375px;
  }

  .u-section-5 .u-text-6 {
    margin-right: 7px;
  }
}

@media (max-width: 991px) {
  .u-section-5 .u-sheet-1 {
    min-height: 291px;
  }

  .u-section-5 .u-layout-wrap-1 {
    width: 720px;
  }

  .u-section-5 .u-layout-cell-1 {
    min-height: 100px;
  }

  .u-section-5 .u-layout-cell-2 {
    min-height: 100px;
  }

  .u-section-5 .u-text-6 {
    margin-right: 0;
  }
}

@media (max-width: 767px) {
  .u-section-5 .u-sheet-1 {
    min-height: 391px;
  }

  .u-section-5 .u-text-1 {
    width: 540px;
  }

  .u-section-5 .u-text-2 {
    width: 540px;
  }

  .u-section-5 .u-layout-wrap-1 {
    width: 540px;
  }

  .u-section-5 .u-container-layout-1 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-5 .u-container-layout-2 {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 575px) {
  .u-section-5 .u-text-1 {
    width: 340px;
    font-size: 1.5rem;
  }

  .u-section-5 .u-text-2 {
    width: 340px;
  }

  .u-section-5 .u-layout-wrap-1 {
    width: 340px;
  }

  .u-section-5 .u-text-3 {
    font-size: 1.5rem;
    width: auto;
    margin-top: 0;
  }

  .u-section-5 .u-text-5 {
    font-size: 1.125rem;
  }

  .u-section-5 .u-text-6 {
    font-size: 1.5rem;
  }

  .u-section-5 .u-text-8 {
    font-size: 1.125rem;
  }
}
.u-section-6 {
  background-image: none;
}

.u-section-6 .u-sheet-1 {
  min-height: 2084px;
}

.u-section-6 .u-text-1 {
  font-weight: 700;
  margin: 89px auto 0;
}

.u-section-6 .u-text-2 {
  font-size: 1.25rem;
  font-weight: 300;
  width: 780px;
  margin: 18px auto 0;
}

.u-section-6 .u-list-1 {
  width: 1060px;
  margin: 20px auto 0;
}

.u-section-6 .u-repeater-1 {
  grid-template-columns: calc(50% - 5px) calc(50% - 5px);
  grid-gap: 10px 10px;
}

.u-section-6 .u-container-layout-1 {
  padding: 4px 30px;
}

.u-section-6 .u-icon-1 {
  height: 65px;
  width: 65px;
  background-image: none;
  margin: 26px auto 0 0;
}

.u-section-6 .u-group-1 {
  width: 403px;
  min-height: 144px;
  margin: -65px 0 0 auto;
}

.u-section-6 .u-container-layout-2 {
  padding: 0 30px;
}

.u-section-6 .u-text-3 {
  font-weight: 700;
  font-size: 1.25rem;
  margin: 0;
}

.u-section-6 .u-container-layout-3 {
  padding: 4px 30px;
}

.u-section-6 .u-icon-2 {
  height: 65px;
  width: 65px;
  background-image: none;
  margin: 26px auto 0 0;
}

.u-section-6 .u-group-2 {
  width: 403px;
  min-height: 144px;
  background-size: auto;
  margin: -65px 0 0 auto;
}

.u-section-6 .u-container-layout-4 {
  padding: 0 30px;
}

.u-section-6 .u-text-4 {
  font-weight: 700;
  font-size: 1.25rem;
  margin: 0;
}

.u-section-6 .u-container-layout-5 {
  padding: 4px 30px;
}

.u-section-6 .u-icon-3 {
  height: 65px;
  width: 65px;
  background-image: none;
  margin: 26px auto 0 0;
}

.u-section-6 .u-group-3 {
  width: 403px;
  min-height: 144px;
  background-size: auto;
  margin: -65px 0 0 auto;
}

.u-section-6 .u-container-layout-6 {
  padding: 0 30px;
}

.u-section-6 .u-text-5 {
  font-weight: 700;
  font-size: 1.25rem;
  margin: 0;
}

.u-section-6 .u-container-layout-7 {
  padding: 4px 30px;
}

.u-section-6 .u-icon-4 {
  height: 65px;
  width: 65px;
  background-image: none;
  margin: 26px auto 0 0;
}

.u-section-6 .u-group-4 {
  width: 403px;
  min-height: 144px;
  background-size: auto;
  margin: -65px 0 0 auto;
}

.u-section-6 .u-container-layout-8 {
  padding: 0 30px;
}

.u-section-6 .u-text-6 {
  font-weight: 700;
  font-size: 1.25rem;
  margin: 0 133px 0 0;
}

.u-section-6 .u-container-layout-9 {
  padding: 4px 30px;
}

.u-section-6 .u-icon-5 {
  height: 65px;
  width: 65px;
  background-image: none;
  margin: 26px auto 0 0;
}

.u-section-6 .u-group-5 {
  width: 403px;
  min-height: 144px;
  background-size: auto;
  margin: -65px 0 0 auto;
}

.u-section-6 .u-container-layout-10 {
  padding: 0 30px;
}

.u-section-6 .u-text-7 {
  font-weight: 700;
  font-size: 1.25rem;
  margin: 0 98px 0 0;
}

.u-section-6 .u-container-layout-11 {
  padding: 4px 30px;
}

.u-section-6 .u-icon-6 {
  height: 65px;
  width: 65px;
  background-image: none;
  margin: 26px auto 0 0;
}

.u-section-6 .u-group-6 {
  width: 403px;
  min-height: 144px;
  background-size: auto;
  margin: -65px 0 0 auto;
}

.u-section-6 .u-container-layout-12 {
  padding: 0 30px;
}

.u-section-6 .u-text-8 {
  font-weight: 700;
  font-size: 1.25rem;
  margin: 0 166px 0 0;
}

.u-section-6 .u-container-layout-13 {
  padding: 4px 30px;
}

.u-section-6 .u-icon-7 {
  height: 65px;
  width: 65px;
  background-image: none;
  margin: 26px auto 0 0;
}

.u-section-6 .u-group-7 {
  width: 403px;
  min-height: 144px;
  background-size: auto;
  margin: -65px 0 0 auto;
}

.u-section-6 .u-container-layout-14 {
  padding: 0 30px;
}

.u-section-6 .u-text-9 {
  font-weight: 700;
  font-size: 1.25rem;
  margin: 0;
}

.u-section-6 .u-text-10 {
  font-weight: 700;
  text-align: center;
  margin: 0 auto;
}

.u-section-6 .u-text-11 {
  font-size: 1.25rem;
  font-weight: 300;
  width: 780px;
  text-align: center;
  margin: 35px auto 0;
}

.u-section-6 .u-list-2 {
  width: 1060px;
  margin: 35px auto 0;
}

.u-section-6 .u-repeater-2 {
  grid-template-columns: calc(50% - 5px) calc(50% - 5px);
  grid-gap: 10px 10px;
  min-height: 632px;
}

.u-section-6 .u-container-layout-15 {
  padding: 4px 30px;
}

.u-section-6 .u-icon-8 {
  height: 65px;
  width: 65px;
  background-image: none;
  margin: 26px auto 0 0;
}

.u-section-6 .u-group-8 {
  width: 403px;
  min-height: 144px;
  margin: -65px 0 0 auto;
}

.u-section-6 .u-container-layout-16 {
  padding: 0 30px;
}

.u-section-6 .u-text-12 {
  font-weight: 700;
  font-size: 1.25rem;
  margin: 0;
}

.u-section-6 .u-container-layout-17 {
  padding: 4px 30px;
}

.u-section-6 .u-icon-9 {
  height: 65px;
  width: 65px;
  background-image: none;
  margin: 26px auto 0 0;
}

.u-section-6 .u-group-9 {
  width: 403px;
  min-height: 144px;
  background-size: auto;
  margin: -65px 0 0 auto;
}

.u-section-6 .u-container-layout-18 {
  padding: 0 30px;
}

.u-section-6 .u-text-13 {
  font-weight: 700;
  font-size: 1.25rem;
  margin: 0;
}

.u-section-6 .u-container-layout-19 {
  padding: 4px 30px;
}

.u-section-6 .u-icon-10 {
  height: 65px;
  width: 65px;
  background-image: none;
  margin: 26px auto 0 0;
}

.u-section-6 .u-group-10 {
  width: 403px;
  min-height: 144px;
  background-size: auto;
  margin: -65px 0 0 auto;
}

.u-section-6 .u-container-layout-20 {
  padding: 0 30px;
}

.u-section-6 .u-text-14 {
  font-weight: 700;
  font-size: 1.25rem;
  margin: 0;
}

.u-section-6 .u-container-layout-21 {
  padding: 4px 30px;
}

.u-section-6 .u-icon-11 {
  height: 65px;
  width: 65px;
  background-image: none;
  margin: 26px auto 0 0;
}

.u-section-6 .u-group-11 {
  width: 403px;
  min-height: 144px;
  background-size: auto;
  margin: -65px 0 0 auto;
}

.u-section-6 .u-container-layout-22 {
  padding: 0 30px;
}

.u-section-6 .u-text-15 {
  font-weight: 700;
  font-size: 1.25rem;
  margin: 0;
}

.u-section-6 .u-container-layout-23 {
  padding: 4px 30px;
}

.u-section-6 .u-icon-12 {
  height: 65px;
  width: 65px;
  background-image: none;
  margin: 26px auto 0 0;
}

.u-section-6 .u-group-12 {
  width: 403px;
  min-height: 144px;
  background-size: auto;
  margin: -65px 0 0 auto;
}

.u-section-6 .u-container-layout-24 {
  padding: 0 30px;
}

.u-section-6 .u-text-16 {
  font-weight: 700;
  font-size: 1.25rem;
  margin: 0;
}

.u-section-6 .u-text-17 {
  font-size: 1.25rem;
  font-weight: 300;
  width: 780px;
  text-align: center;
  margin: 20px auto 60px;
}

@media (max-width: 1199px) {
  .u-section-6 .u-sheet-1 {
    min-height: 1968px;
  }

  .u-section-6 .u-list-1 {
    margin-right: initial;
    margin-left: initial;
    width: auto;
  }

  .u-section-6 .u-repeater-1 {
    min-height: 750px;
  }

  .u-section-6 .u-container-layout-1 {
    padding-right: 28px;
  }

  .u-section-6 .u-container-layout-2 {
    padding-left: 0;
    padding-right: 0;
  }

  .u-section-6 .u-text-3 {
    width: auto;
    margin-left: 78px;
  }

  .u-section-6 .u-container-layout-3 {
    padding-right: 28px;
  }

  .u-section-6 .u-container-layout-4 {
    padding-left: 0;
    padding-right: 0;
  }

  .u-section-6 .u-text-4 {
    width: auto;
    margin-left: 78px;
  }

  .u-section-6 .u-container-layout-5 {
    padding-right: 28px;
  }

  .u-section-6 .u-container-layout-6 {
    padding-left: 0;
    padding-right: 0;
  }

  .u-section-6 .u-text-5 {
    width: auto;
    margin-left: 78px;
  }

  .u-section-6 .u-container-layout-7 {
    padding-right: 28px;
  }

  .u-section-6 .u-container-layout-8 {
    padding-left: 0;
    padding-right: 0;
  }

  .u-section-6 .u-text-6 {
    width: auto;
    margin-right: 0;
    margin-left: 78px;
  }

  .u-section-6 .u-container-layout-9 {
    padding-right: 28px;
  }

  .u-section-6 .u-container-layout-10 {
    padding-left: 0;
    padding-right: 0;
  }

  .u-section-6 .u-text-7 {
    width: auto;
    margin-right: 0;
    margin-left: 78px;
  }

  .u-section-6 .u-container-layout-11 {
    padding-right: 28px;
  }

  .u-section-6 .u-container-layout-12 {
    padding-left: 0;
    padding-right: 0;
  }

  .u-section-6 .u-text-8 {
    width: auto;
    margin-right: 0;
    margin-left: 78px;
  }

  .u-section-6 .u-container-layout-13 {
    padding-right: 28px;
  }

  .u-section-6 .u-container-layout-14 {
    padding-left: 0;
    padding-right: 0;
  }

  .u-section-6 .u-text-9 {
    width: auto;
    margin-left: 78px;
  }

  .u-section-6 .u-list-2 {
    margin-right: initial;
    margin-left: initial;
    width: auto;
  }

  .u-section-6 .u-repeater-2 {
    min-height: 560px;
  }

  .u-section-6 .u-container-layout-16 {
    padding-left: 0;
    padding-right: 0;
  }

  .u-section-6 .u-text-12 {
    width: auto;
    margin-left: 80px;
  }

  .u-section-6 .u-container-layout-18 {
    padding-left: 0;
    padding-right: 0;
  }

  .u-section-6 .u-text-13 {
    width: auto;
    margin-left: 80px;
  }

  .u-section-6 .u-container-layout-20 {
    padding-left: 0;
    padding-right: 0;
  }

  .u-section-6 .u-text-14 {
    width: auto;
    margin-left: 80px;
  }

  .u-section-6 .u-container-layout-22 {
    padding-left: 0;
    padding-right: 0;
  }

  .u-section-6 .u-text-15 {
    width: auto;
    margin-left: 80px;
  }

  .u-section-6 .u-container-layout-24 {
    padding-left: 0;
    padding-right: 0;
  }

  .u-section-6 .u-text-16 {
    width: auto;
    margin-left: 80px;
  }
}

@media (max-width: 991px) {
  .u-section-6 .u-sheet-1 {
    min-height: 2199px;
  }

  .u-section-6 .u-text-2 {
    width: 720px;
  }

  .u-section-6 .u-repeater-1 {
    min-height: 874px;
  }

  .u-section-6 .u-container-layout-1 {
    padding-bottom: 0;
  }

  .u-section-6 .u-icon-1 {
    margin-top: 20px;
    margin-left: auto;
  }

  .u-section-6 .u-group-1 {
    width: 295px;
    min-height: 175px;
    margin-top: -59px;
    margin-right: auto;
  }

  .u-section-6 .u-text-3 {
    margin-top: 76px;
    margin-left: 0;
  }

  .u-section-6 .u-container-layout-3 {
    padding-bottom: 0;
  }

  .u-section-6 .u-icon-2 {
    margin-top: 20px;
    margin-left: auto;
  }

  .u-section-6 .u-group-2 {
    width: 295px;
    min-height: 175px;
    margin-top: -59px;
    margin-right: auto;
  }

  .u-section-6 .u-text-4 {
    margin-top: 76px;
    margin-left: 0;
  }

  .u-section-6 .u-container-layout-5 {
    padding-bottom: 0;
  }

  .u-section-6 .u-icon-3 {
    margin-top: 20px;
    margin-left: auto;
  }

  .u-section-6 .u-group-3 {
    width: 295px;
    min-height: 175px;
    margin-top: -59px;
    margin-right: auto;
  }

  .u-section-6 .u-text-5 {
    margin-top: 76px;
    margin-left: 0;
  }

  .u-section-6 .u-container-layout-7 {
    padding-bottom: 0;
  }

  .u-section-6 .u-icon-4 {
    margin-top: 20px;
    margin-left: auto;
  }

  .u-section-6 .u-group-4 {
    width: 295px;
    min-height: 175px;
    margin-top: -59px;
    margin-right: auto;
  }

  .u-section-6 .u-text-6 {
    margin-top: 76px;
    margin-left: 0;
  }

  .u-section-6 .u-container-layout-9 {
    padding-bottom: 0;
  }

  .u-section-6 .u-icon-5 {
    margin-top: 20px;
    margin-left: auto;
  }

  .u-section-6 .u-group-5 {
    width: 295px;
    min-height: 175px;
    margin-top: -59px;
    margin-right: auto;
  }

  .u-section-6 .u-text-7 {
    margin-top: 76px;
    margin-left: 0;
  }

  .u-section-6 .u-container-layout-11 {
    padding-bottom: 0;
  }

  .u-section-6 .u-icon-6 {
    margin-top: 20px;
    margin-left: auto;
  }

  .u-section-6 .u-group-6 {
    width: 295px;
    min-height: 175px;
    margin-top: -59px;
    margin-right: auto;
  }

  .u-section-6 .u-text-8 {
    margin-top: 76px;
    margin-left: 0;
  }

  .u-section-6 .u-container-layout-13 {
    padding-bottom: 0;
  }

  .u-section-6 .u-icon-7 {
    margin-top: 20px;
    margin-left: auto;
  }

  .u-section-6 .u-group-7 {
    width: 295px;
    min-height: 175px;
    margin-top: -59px;
    margin-right: auto;
  }

  .u-section-6 .u-text-9 {
    margin-top: 76px;
    margin-left: 0;
  }

  .u-section-6 .u-text-10 {
    width: 404px;
    margin-top: 79px;
  }

  .u-section-6 .u-text-11 {
    width: 720px;
  }

  .u-section-6 .u-container-layout-15 {
    padding: 0;
  }

  .u-section-6 .u-icon-8 {
    margin-top: 11px;
    margin-right: 134px;
    margin-left: auto;
  }

  .u-section-6 .u-group-8 {
    width: 333px;
    min-height: 146px;
    margin-top: -20px;
    margin-bottom: -8px;
    margin-left: 22px;
  }

  .u-section-6 .u-text-12 {
    margin-top: 32px;
    margin-left: 24px;
    margin-right: 24px;
  }

  .u-section-6 .u-container-layout-17 {
    padding: 0;
  }

  .u-section-6 .u-icon-9 {
    margin-top: 11px;
    margin-right: 134px;
    margin-left: auto;
  }

  .u-section-6 .u-group-9 {
    width: 333px;
    min-height: 146px;
    margin-top: -20px;
    margin-bottom: -8px;
    margin-left: 22px;
  }

  .u-section-6 .u-text-13 {
    margin-top: 32px;
    margin-left: 24px;
    margin-right: 24px;
  }

  .u-section-6 .u-container-layout-19 {
    padding: 0;
  }

  .u-section-6 .u-icon-10 {
    margin-top: 11px;
    margin-right: 134px;
    margin-left: auto;
  }

  .u-section-6 .u-group-10 {
    width: 333px;
    min-height: 146px;
    margin-top: -20px;
    margin-bottom: -8px;
    margin-left: 22px;
  }

  .u-section-6 .u-text-14 {
    margin-top: 32px;
    margin-left: 24px;
    margin-right: 24px;
  }

  .u-section-6 .u-container-layout-21 {
    padding: 0;
  }

  .u-section-6 .u-icon-11 {
    margin-top: 11px;
    margin-right: 134px;
    margin-left: auto;
  }

  .u-section-6 .u-group-11 {
    width: 333px;
    min-height: 146px;
    margin-top: -20px;
    margin-bottom: -8px;
    margin-left: 22px;
  }

  .u-section-6 .u-text-15 {
    margin-top: 32px;
    margin-left: 24px;
    margin-right: 24px;
  }

  .u-section-6 .u-container-layout-23 {
    padding: 0;
  }

  .u-section-6 .u-icon-12 {
    margin-top: 11px;
    margin-right: 134px;
    margin-left: auto;
  }

  .u-section-6 .u-group-12 {
    width: 333px;
    min-height: 146px;
    margin-top: -20px;
    margin-bottom: -8px;
    margin-left: 22px;
  }

  .u-section-6 .u-text-16 {
    margin-top: 32px;
    margin-left: 24px;
    margin-right: 24px;
  }

  .u-section-6 .u-text-17 {
    width: 720px;
  }
}

@media (max-width: 767px) {
  .u-section-6 .u-sheet-1 {
    min-height: 2174px;
  }

  .u-section-6 .u-text-2 {
    width: 540px;
  }

  .u-section-6 .u-repeater-1 {
    min-height: 802px;
  }

  .u-section-6 .u-container-layout-1 {
    padding-left: 0;
    padding-right: 0;
  }

  .u-section-6 .u-group-1 {
    width: 255px;
    min-height: 151px;
    margin-right: 0;
  }

  .u-section-6 .u-text-3 {
    font-size: 1rem;
  }

  .u-section-6 .u-container-layout-3 {
    padding-left: 0;
    padding-right: 0;
  }

  .u-section-6 .u-group-2 {
    width: 255px;
    min-height: 151px;
    margin-right: 0;
  }

  .u-section-6 .u-text-4 {
    font-size: 1rem;
  }

  .u-section-6 .u-container-layout-5 {
    padding-left: 0;
    padding-right: 0;
  }

  .u-section-6 .u-group-3 {
    width: 255px;
    min-height: 151px;
    margin-right: 0;
  }

  .u-section-6 .u-text-5 {
    font-size: 1rem;
  }

  .u-section-6 .u-container-layout-7 {
    padding-left: 0;
    padding-right: 0;
  }

  .u-section-6 .u-group-4 {
    width: 255px;
    min-height: 151px;
    margin-right: 0;
  }

  .u-section-6 .u-text-6 {
    font-size: 1rem;
  }

  .u-section-6 .u-container-layout-9 {
    padding-left: 0;
    padding-right: 0;
  }

  .u-section-6 .u-group-5 {
    width: 255px;
    min-height: 151px;
    margin-right: 0;
  }

  .u-section-6 .u-text-7 {
    font-size: 1rem;
  }

  .u-section-6 .u-container-layout-11 {
    padding-left: 0;
    padding-right: 0;
  }

  .u-section-6 .u-group-6 {
    width: 255px;
    min-height: 151px;
    margin-right: 0;
  }

  .u-section-6 .u-text-8 {
    font-size: 1rem;
  }

  .u-section-6 .u-container-layout-13 {
    padding-left: 0;
    padding-right: 0;
  }

  .u-section-6 .u-group-7 {
    width: 255px;
    min-height: 151px;
    margin-right: 0;
  }

  .u-section-6 .u-text-9 {
    font-size: 1rem;
  }

  .u-section-6 .u-text-10 {
    margin-top: 74px;
  }

  .u-section-6 .u-text-11 {
    width: 540px;
  }

  .u-section-6 .u-icon-8 {
    margin-top: 10px;
    margin-right: auto;
  }

  .u-section-6 .u-group-8 {
    min-height: 116px;
    margin-top: -8px;
    margin-bottom: 0;
    margin-right: initial;
    margin-left: initial;
    width: auto;
  }

  .u-section-6 .u-text-12 {
    font-size: 1rem;
    margin-top: 0;
  }

  .u-section-6 .u-icon-9 {
    margin-top: 10px;
    margin-right: auto;
  }

  .u-section-6 .u-group-9 {
    min-height: 116px;
    margin-top: -8px;
    margin-bottom: 0;
    margin-right: initial;
    margin-left: initial;
    width: auto;
  }

  .u-section-6 .u-text-13 {
    font-size: 1rem;
    margin-top: 0;
  }

  .u-section-6 .u-icon-10 {
    margin-top: 10px;
    margin-right: auto;
  }

  .u-section-6 .u-group-10 {
    min-height: 116px;
    margin-top: -8px;
    margin-bottom: 0;
    margin-right: initial;
    margin-left: initial;
    width: auto;
  }

  .u-section-6 .u-text-14 {
    font-size: 1rem;
    margin-top: 0;
  }

  .u-section-6 .u-icon-11 {
    margin-top: 10px;
    margin-right: auto;
  }

  .u-section-6 .u-group-11 {
    min-height: 116px;
    margin-top: -8px;
    margin-bottom: 0;
    margin-right: initial;
    margin-left: initial;
    width: auto;
  }

  .u-section-6 .u-text-15 {
    font-size: 1rem;
    margin-top: 0;
  }

  .u-section-6 .u-icon-12 {
    margin-top: 10px;
    margin-right: auto;
  }

  .u-section-6 .u-group-12 {
    min-height: 116px;
    margin-top: -8px;
    margin-bottom: 0;
    margin-right: initial;
    margin-left: initial;
    width: auto;
  }

  .u-section-6 .u-text-16 {
    font-size: 1rem;
    margin-top: 0;
  }

  .u-section-6 .u-text-17 {
    width: 540px;
  }
}

@media (max-width: 575px) {
  .u-section-6 .u-sheet-1 {
    min-height: 3156px;
  }

  .u-section-6 .u-text-2 {
    width: 340px;
  }

  .u-section-6 .u-repeater-1 {
    grid-template-columns: 100%;
  }

  .u-section-6 .u-icon-1 {
    margin-top: 0;
  }

  .u-section-6 .u-group-1 {
    min-height: 155px;
    margin-right: auto;
  }

  .u-section-6 .u-container-layout-2 {
    padding-bottom: 0;
  }

  .u-section-6 .u-text-3 {
    margin: 83px 10px -11px;
  }

  .u-section-6 .u-icon-2 {
    margin-top: 0;
  }

  .u-section-6 .u-group-2 {
    min-height: 155px;
    margin-right: auto;
  }

  .u-section-6 .u-container-layout-4 {
    padding-bottom: 0;
  }

  .u-section-6 .u-text-4 {
    margin: 83px 10px -11px;
  }

  .u-section-6 .u-icon-3 {
    margin-top: 0;
  }

  .u-section-6 .u-group-3 {
    min-height: 155px;
    margin-right: auto;
  }

  .u-section-6 .u-container-layout-6 {
    padding-bottom: 0;
  }

  .u-section-6 .u-text-5 {
    margin: 83px 10px -11px;
  }

  .u-section-6 .u-icon-4 {
    margin-top: 0;
  }

  .u-section-6 .u-group-4 {
    min-height: 155px;
    margin-right: auto;
  }

  .u-section-6 .u-container-layout-8 {
    padding-bottom: 0;
  }

  .u-section-6 .u-text-6 {
    margin: 83px 10px -11px;
  }

  .u-section-6 .u-icon-5 {
    margin-top: 0;
  }

  .u-section-6 .u-group-5 {
    min-height: 155px;
    margin-right: auto;
  }

  .u-section-6 .u-container-layout-10 {
    padding-bottom: 0;
  }

  .u-section-6 .u-text-7 {
    margin: 83px 10px -11px;
  }

  .u-section-6 .u-icon-6 {
    margin-top: 0;
  }

  .u-section-6 .u-group-6 {
    min-height: 155px;
    margin-right: auto;
  }

  .u-section-6 .u-container-layout-12 {
    padding-bottom: 0;
  }

  .u-section-6 .u-text-8 {
    margin: 83px 10px -11px;
  }

  .u-section-6 .u-icon-7 {
    margin-top: 0;
  }

  .u-section-6 .u-group-7 {
    min-height: 155px;
    margin-right: auto;
  }

  .u-section-6 .u-container-layout-14 {
    padding-bottom: 0;
  }

  .u-section-6 .u-text-9 {
    margin: 83px 10px -11px;
  }

  .u-section-6 .u-text-10 {
    margin-top: 40px;
    width: 340px;
  }

  .u-section-6 .u-text-11 {
    width: 340px;
  }

  .u-section-6 .u-repeater-2 {
    grid-template-columns: 100%;
  }

  .u-section-6 .u-group-8 {
    min-height: 140px;
    width: auto;
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-6 .u-container-layout-16 {
    padding-bottom: 0;
  }

  .u-section-6 .u-text-12 {
    margin-top: 42px;
    margin-left: 22px;
    margin-right: 22px;
  }

  .u-section-6 .u-container-layout-18 {
    padding-bottom: 0;
  }

  .u-section-6 .u-text-13 {
    margin-top: 42px;
    margin-left: 22px;
    margin-right: 22px;
  }

  .u-section-6 .u-container-layout-20 {
    padding-bottom: 0;
  }

  .u-section-6 .u-text-14 {
    margin-top: 42px;
    margin-left: 22px;
    margin-right: 22px;
  }

  .u-section-6 .u-container-layout-22 {
    padding-bottom: 0;
  }

  .u-section-6 .u-text-15 {
    margin-top: 42px;
    margin-left: 22px;
    margin-right: 22px;
  }

  .u-section-6 .u-container-layout-24 {
    padding-bottom: 0;
  }

  .u-section-6 .u-text-16 {
    margin-top: 42px;
    margin-left: 22px;
    margin-right: 22px;
  }

  .u-section-6 .u-text-17 {
    font-size: 1rem;
    width: auto;
    margin: 145px 0 55px;
  }
}
.u-section-7 {
  background-image: none;
}

.u-section-7 .u-sheet-1 {
  min-height: 901px;
}

.u-section-7 .u-text-1 {
  font-size: 3.75rem;
  letter-spacing: normal;
  font-family: Raleway, sans-serif;
  margin: 60px 0 0 296px;
}

.u-section-7 .u-shape-1 {
  width: 331px;
  height: 625px;
  margin: 36px 338px 0 auto;
}

.u-section-7 .u-image-1 {
  width: 700px;
  height: 475px;
  margin: -522px 81px 0 auto;
}

.u-section-7 .u-slider-1 {
  min-height: 377px;
  width: 471px;
  margin: -542px auto 0 45px;
}

.u-section-7 .u-carousel-indicators-1 {
  position: absolute;
  width: auto;
  height: auto;
  top: auto;
  bottom: 25px;
}

.u-section-7 .u-carousel-item-1 {
  background-image: none;
}

.u-section-7 .u-container-layout-1 {
  padding: 40px 60px 45px 70px;
}

.u-section-7 .u-text-2 {
  font-family: Raleway, sans-serif;
  margin: 0 43px 0 0;
}

.u-section-7 .u-text-3 {
  font-size: 1.0625rem;
  margin: 20px 0 0;
}

.u-section-7 .u-carousel-item-2 {
  background-image: none;
}

.u-section-7 .u-container-layout-2 {
  padding: 40px 60px 45px 70px;
}

.u-section-7 .u-text-4 {
  font-family: Raleway, sans-serif;
  margin: 56px 20px 0 0;
}

.u-section-7 .u-text-5 {
  font-size: 1.0625rem;
  margin: 20px 0 0;
}

.u-section-7 .u-carousel-control-1 {
  width: 43px;
  height: 43px;
  left: 0;
  position: absolute;
  background-image: none;
  right: auto;
}

.u-section-7 .u-carousel-control-2 {
  width: 43px;
  height: 43px;
  left: auto;
  position: absolute;
  background-image: none;
  right: 0;
}

@media (max-width: 1199px) {
  .u-section-7 .u-text-1 {
    margin-left: 96px;
  }

  .u-section-7 .u-container-layout-1 {
    padding-left: 45px;
    padding-right: 45px;
  }

  .u-section-7 .u-text-2 {
    margin-right: 0;
  }

  .u-section-7 .u-container-layout-2 {
    padding-left: 45px;
    padding-right: 45px;
  }

  .u-section-7 .u-text-4 {
    margin-right: 0;
  }
}

@media (max-width: 991px) {
  .u-section-7 .u-sheet-1 {
    min-height: 985px;
  }

  .u-section-7 .u-text-1 {
    margin-left: 0;
  }

  .u-section-7 .u-image-1 {
    margin-right: 20px;
  }

  .u-section-7 .u-container-layout-1 {
    padding: 30px 55px;
  }

  .u-section-7 .u-container-layout-2 {
    padding: 30px 55px;
  }
}

@media (max-width: 767px) {
  .u-section-7 .u-sheet-1 {
    min-height: 1004px;
  }

  .u-section-7 .u-text-1 {
    font-size: 3rem;
  }

  .u-section-7 .u-shape-1 {
    margin-right: 209px;
  }

  .u-section-7 .u-image-1 {
    width: 540px;
    height: 366px;
    margin-right: 0;
  }

  .u-section-7 .u-slider-1 {
    height: auto;
    margin: -77px 20px 35px auto;
  }

  .u-section-7 .u-container-layout-1 {
    padding-left: 50px;
    padding-right: 50px;
  }

  .u-section-7 .u-container-layout-2 {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media (max-width: 575px) {
  .u-section-7 .u-sheet-1 {
    min-height: 1022px;
  }

  .u-section-7 .u-text-1 {
    font-size: 2.25rem;
  }

  .u-section-7 .u-shape-1 {
    width: 351px;
    margin-right: auto;
    margin-left: -20px;
  }

  .u-section-7 .u-image-1 {
    width: 340px;
    height: 230px;
    margin-top: -601px;
    margin-right: -20px;
  }

  .u-section-7 .u-slider-1 {
    min-height: 483px;
    width: 340px;
    margin-top: 0;
    margin-right: -20px;
  }

  .u-section-7 .u-container-layout-1 {
    padding-left: 45px;
    padding-right: 45px;
  }

  .u-section-7 .u-container-layout-2 {
    padding-left: 45px;
    padding-right: 45px;
  }
}
.u-section-8 {
  background-image: url("../images/ggh.jpg");
  background-position: 50% 50%;
}

.u-section-8 .u-sheet-1 {
  min-height: 734px;
}

.u-section-8 .u-layout-wrap-1 {
  width: 1140px;
  margin: 0 auto;
}

.u-section-8 .u-layout-cell-1 {
  min-height: 1027px;
}

.u-section-8 .u-container-layout-1 {
  padding: 60px 0;
}

.u-section-8 .u-text-1 {
  font-size: 1.25rem;
  font-style: italic;
  line-height: 2;
  margin: 247px 0 0 30px;
}

.u-section-8 .u-btn-1 {
  background-image: none;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 1rem;
  border-style: none;
  font-weight: 700;
  margin: 30px auto 0 30px;
  padding: 10px 52px 10px 51px;
}

.u-section-8 .u-image-1 {
  background-position: 50% 50%;
  min-height: 734px;
}

.u-section-8 .u-container-layout-2 {
  padding: 30px 0;
}

.u-section-8 .u-group-1 {
  min-height: 209px;
  width: 582px;
  background-image: none;
  margin: 30px auto 0 -440px;
}

.u-section-8 .u-container-layout-3 {
  padding: 30px;
}

.u-section-8 .u-text-2 {
  text-align: left;
  font-size: 3.75rem;
  font-family: Raleway, sans-serif;
  font-weight: bold;
  text-transform: none;
  letter-spacing: normal;
  margin: 0;
}

@media (max-width: 1199px) {
  .u-section-8 .u-sheet-1 {
    min-height: 476px;
  }

  .u-section-8 .u-layout-wrap-1 {
    width: 940px;
  }

  .u-section-8 .u-layout-cell-1 {
    min-height: 697px;
  }

  .u-section-8 .u-text-1 {
    margin-left: 0;
  }

  .u-section-8 .u-btn-1 {
    margin-left: 0;
  }

  .u-section-8 .u-image-1 {
    min-height: 697px;
  }

  .u-section-8 .u-group-1 {
    width: 499px;
    margin-left: -357px;
  }
}

@media (max-width: 991px) {
  .u-section-8 .u-sheet-1 {
    min-height: 669px;
  }

  .u-section-8 .u-layout-wrap-1 {
    margin-right: initial;
    margin-left: initial;
    width: auto;
  }

  .u-section-8 .u-layout-cell-1 {
    min-height: 669px;
  }

  .u-section-8 .u-container-layout-1 {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .u-section-8 .u-image-1 {
    min-height: 669px;
  }

  .u-section-8 .u-group-1 {
    min-height: 192px;
    width: 404px;
    margin-top: 11px;
    margin-left: -270px;
  }

  .u-section-8 .u-text-2 {
    margin-top: 30px;
  }
}

@media (max-width: 767px) {
  .u-section-8 .u-sheet-1 {
    min-height: 1225px;
  }

  .u-section-8 .u-layout-wrap-1 {
    margin-top: 130px;
    margin-bottom: 54px;
    width: auto;
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-8 .u-layout-cell-1 {
    min-height: 317px;
    order: 1;
  }

  .u-section-8 .u-text-1 {
    margin-top: 0;
  }

  .u-section-8 .u-image-1 {
    min-height: 724px;
    order: 0;
  }

  .u-section-8 .u-container-layout-2 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .u-section-8 .u-group-1 {
    min-height: 180px;
    margin-top: -77px;
    margin-left: 39px;
  }

  .u-section-8 .u-container-layout-3 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .u-section-8 .u-text-2 {
    width: auto;
    margin-top: 35px;
  }
}

@media (max-width: 575px) {
  .u-section-8 .u-sheet-1 {
    min-height: 1018px;
  }

  .u-section-8 .u-layout-wrap-1 {
    margin-top: 140px;
    width: auto;
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-8 .u-layout-cell-1 {
    min-height: 100px;
  }

  .u-section-8 .u-text-1 {
    width: auto;
  }

  .u-section-8 .u-btn-1 {
    margin-left: auto;
  }

  .u-section-8 .u-image-1 {
    min-height: 456px;
  }

  .u-section-8 .u-group-1 {
    min-height: 154px;
    width: 310px;
    margin-top: -90px;
    margin-left: 12px;
  }

  .u-section-8 .u-text-2 {
    font-size: 2.25rem;
  }
}
.u-section-10 .u-sheet-1 {
  min-height: 100vh;
}

.u-section-10 .u-text-1 {
  margin: 58px 0 0;
}

.u-section-10 .u-slider-1 {
  min-height: 438px;
  width: 967px;
  margin: 33px auto 60px 87px;
}

.u-section-10 .u-carousel-indicators-1 {
  position: absolute;
  width: auto;
  height: auto;
  top: auto;
  bottom: -20px;
}

.u-section-10 .u-image-1 {
  background-position: 50% 50%;
}

.u-section-10 .u-container-layout-1 {
  padding: 30px;
}

.u-section-10 .u-image-2 {
  background-position: 50% 50%;
}

.u-section-10 .u-container-layout-2 {
  padding: 30px;
}

.u-section-10 .u-image-3 {
  background-position: 50% 50%;
}

.u-section-10 .u-container-layout-3 {
  padding: 30px;
}

.u-section-10 .u-container-layout-4 {
  padding: 30px 50px;
}

.u-section-10 .u-container-layout-5 {
  padding: 30px 50px;
}

.u-section-10 .u-carousel-control-1 {
  width: 50px;
  height: 50px;
  background-image: none;
  left: -40px;
  position: absolute;
  right: auto;
}

.u-section-10 .u-carousel-control-2 {
  width: 50px;
  height: 50px;
  background-image: none;
  left: auto;
  position: absolute;
  right: -40px;
}

@media (max-width: 1199px) {
  .u-section-10 .u-slider-1 {
    width: 940px;
    margin-left: 0;
  }
}

@media (max-width: 991px) {
  .u-section-10 .u-slider-1 {
    width: 720px;
  }
}

@media (max-width: 767px) {
  .u-section-10 .u-slider-1 {
    width: 540px;
  }

  .u-section-10 .u-container-layout-1 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-10 .u-container-layout-2 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-10 .u-container-layout-3 {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 575px) {
  .u-section-10 .u-slider-1 {
    width: 340px;
  }
}
