@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;200;300;400;500;600;700&family=Roboto:wght@100;300;400;500;700;900&display=swap");

/* CSS Common */
:root {
  /* --color: #FF9100;
  --spacing: 1.75rem; */
}
html {
  box-sizing: border-box;
}
* {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: "Roboto", sans-serif;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Josefin Sans", sans-serif;
}
input,
textarea,
select,
button {
  outline: none;
}

section {
  background: linear-gradient(-45deg, #efc1e6, #ec8fb3, #a2dbf0, #88f0d8);
  background-size: 100% 100%;
  -webkit-animation: gradient 15s ease infinite;
  animation: gradient 5s ease infinite;
}

@-webkit-keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

a {
  text-decoration: none;
  transition: all 0.5s ease-in-out;
}

a:hover {
  transition: all 0.5s ease-in-out;
}
.card h3 {
  font-size: 1.7rem;
  color: red;
  font-weight: 800;
}
.content h2 {
  color: red;
  font-weight: 900;
  font-size: 5rem;
  text-shadow: 0 20px 30px red;
}
.content h2:hover {
  color: gold;
  font-weight: 900;
  transform: scale(1.02);
}
.content p {
  color: #0c4c91;
  font-size: 5rem;
  font-weight: 900;
}
.content p:hover {
  color: red;
  font-weight: 900;
}
.main a {
  background-color: #0c4c91;
  color: white;
  width: 8%;
  text-align: center;
  padding: 15px 0px 15px 0px;
  padding: 1rem 1rem;
  font-size: 1.2rem;
  font-weight: 600;
  text-decoration: none;
  border-radius: 10px;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}

.main a:hover {
  background: white;
  color: #404140;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  transform: translateY(10px);
}
.hero {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.main1 {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-right: 0;
  padding-right: 0;
}

.wrapper {
  width: 55%;
  display: flex;
  padding-left: 5rem;
}

.main1 figcaption {
  width: 45%;
  padding-top: 5rem;
  margin-right: 0;
  padding-right: 0;
}
.main1 figcaption img {
  width: 600px;
  height: 800px;
  border: 15px double greenyellow;
}

.main1 figcaption img:hover {
  transform: scale(1.05);
  filter: drop-shadow(0 10px 20px greenyellow);
}

.main {
  width: 100%;
  display: flex;
  align-items: center;
  align-content: stretch;
  padding: 2% 2%;
  flex-wrap: wrap;
}

.main > img {
  /* padding-left: 3%;
  padding: 2% 3%; */
  width: 40%;
  border: 15px double greenyellow;
}
.main > img:hover {
  transform: scale(1.05);
  filter: drop-shadow(0 10px 20px greenyellow);
}
.content {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: last baseline;
  padding: 10% 10%;
}

.content h2 {
  margin-bottom: 1.2rem;
  font-size: 2rem;
}

.content p {
  font-size: 1.5rem;
  font-weight: 600;
  color: black;
  margin-bottom: 1rem;
}

.content span {
  display: block;
  margin: 2rem 0;
}

@media only screen and (max-width: 992px) {
  .main > img {
    width: 80%;
  }
  .main1 figcaption img {
    width: 100%;
  }
  .content {
    width: 100%;
    padding: 5%;
  }
  .wrapper {
    width: 100%;
    padding: 5%;
  }
}

.front,
.back {
  box-shadow: 0px 0px 15px 7px rgba(0, 0, 0, 0.75);
  border-radius: 20px;
}

.front {
  background-image: url("https://i.pinimg.com/564x/11/ae/62/11ae6278a5f4b092f736f9bac58ba653.jpg");

  background-size: cover;

  background-color: rgb(226, 163, 226);
  background-blend-mode: multiply;
}

.back {
  background-image: url("https://i.pinimg.com/564x/7e/a5/22/7ea52247305feb6861267a3df794ea10.jpg");

  background-size: cover;
  background-color: lightblue;
  background-blend-mode: multiply;
}

/*Flip-Card Functionallity */
.wrapper {
  width: 60%;
  display: inline-block;
}

.wrapper:hover .card {
  transform: rotateY(180deg);
}

.card {
  width: 600px;
  height: 800px;
  position: relative;
  transform: perspective(600px);
  transform-style: preserve-3d;
  transition: all 0.7s linear;
}

.back,
.front {
  position: absolute;
  padding: 5rem 3.5rem;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  backface-visibility: hidden;
  font-size: 1.3rem;
  font-weight: 600;
  color: black;
}

.back {
  transform: rotateY(180deg);
}

.back a {
  background-color: #0c4c91;
  color: white;
  width: 8%;
  text-align: center;
  padding: 15px 0px 15px 0px;
  padding: 1rem 1rem;
  font-size: 1.2rem;
  font-weight: 600;
  text-decoration: none;
  border-radius: 10px;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}

.back a:hover {
  background: white;
  color: #404140;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  transform: translateY(10px);
}
