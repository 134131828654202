.thankyou-page .u-sheet-1 {
  min-height: 796px;
}

.thankyou-page .u-group-1 {
  width: 747px;
  min-height: 639px;
  margin: 57px auto 60px;
}

.thankyou-page .u-container-layout-1 {
  padding: 30px 29px;
}

.thankyou-page .u-text-1 {
  font-weight: 700;
  background-image: none;
  font-size: 3rem;
  margin: 6px auto 0;
}

.thankyou-page .u-text-2 {
  text-align: center;
  margin: 6px 21px 0;
}

.thankyou-page .u-text-3 {
  font-size: 1.25rem;
  text-align: justify;
  margin: 6px 0 0 21px;
}

.thankyou-page .u-form-1 {
  height: 60px;
  width: 570px;
  margin: 8px auto 0;
}

.thankyou-page .u-input-1 {
  font-size: 1.125rem;
}

.thankyou-page .u-btn-1 {
  font-size: 1.125rem;
  border-style: solid;
}

.thankyou-page .u-text-4 {
  font-size: 1.25rem;
  margin: 44px 384px 0 21px;
}

.thankyou-page .u-btn-2 {
  border-style: none;
  margin: 24px auto 0;
  padding: 8px 28px 8px 27px;
}

.thankyou-page .u-text-5 {
  font-size: 1.25rem;
  text-align: left;
  margin: 44px 345px 0 21px;
}

.thankyou-page .u-text-6 {
  text-align: center;
  margin: 0 auto 0 21px;
}

.thankyou-page .u-btn-3 {
  border-style: none;
  margin: 21px auto 0 270px;
  padding: 8px 28px 8px 27px;
}

@media (max-width: 991px) {
  .thankyou-page .u-group-1 {
    width: 720px;
  }

  .thankyou-page .u-text-2 {
    margin-left: 8px;
    margin-right: 8px;
  }

  .thankyou-page .u-text-3 {
    margin-left: 0;
  }

  .thankyou-page .u-text-4 {
    margin-right: 378px;
    margin-left: 0;
  }

  .thankyou-page .u-btn-2 {
    margin-top: 10px;
  }

  .thankyou-page .u-text-5 {
    width: auto;
    margin-right: 339px;
    margin-left: 0;
  }

  .thankyou-page .u-text-6 {
    width: auto;
    margin-left: 0;
  }

  .thankyou-page .u-btn-3 {
    margin-top: 10px;
    margin-left: 256px;
  }
}

@media (max-width: 767px) {
  .thankyou-page .u-group-1 {
    margin-bottom: 36px;
    min-height: 741px;
    margin-right: initial;
    margin-left: initial;
    width: auto;
  }

  .thankyou-page .u-container-layout-1 {
    padding-left: 0;
    padding-right: 0;
  }

  .thankyou-page .u-text-1 {
    margin-top: 44px;
  }

  .thankyou-page .u-text-2 {
    margin-left: 20px;
    margin-right: 20px;
  }

  .thankyou-page .u-text-3 {
    margin-left: 20px;
    margin-right: 20px;
  }

  .thankyou-page .u-form-1 {
    height: 134px;
    width: 520px;
    margin-top: 9px;
  }

  .thankyou-page .u-text-4 {
    width: auto;
    margin-top: 23px;
    margin-right: 262px;
    margin-left: 14px;
  }

  .thankyou-page .u-btn-2 {
    margin-top: 18px;
    margin-left: 189px;
  }

  .thankyou-page .u-text-5 {
    margin-right: 201px;
    margin-left: 14px;
  }

  .thankyou-page .u-text-6 {
    margin-top: 14px;
  }

  .thankyou-page .u-btn-3 {
    margin-top: 6px;
    margin-left: auto;
  }
}

@media (max-width: 575px) {
  .thankyou-page .u-sheet-1 {
    min-height: 779px;
  }

  .thankyou-page .u-group-1 {
    margin-top: 40px;
    width: auto;
    margin-right: initial;
    margin-left: initial;
  }

  .thankyou-page .u-container-layout-1 {
    padding-top: 22px;
    padding-bottom: 22px;
  }

  .thankyou-page .u-text-1 {
    font-size: 1.875rem;
    font-weight: bold;
    margin-top: 14px;
  }

  .thankyou-page .u-text-3 {
    font-size: 1.125rem;
    width: auto;
    margin-top: 29px;
  }

  .thankyou-page .u-form-1 {
    height: 130px;
    width: 323px;
    margin-top: 6px;
    margin-left: 9px;
    margin-right: 9px;
  }

  .thankyou-page .u-btn-1 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .thankyou-page .u-text-4 {
    font-size: 1.125rem;
    margin-top: 16px;
    margin-right: 76px;
    margin-left: 20px;
  }

  .thankyou-page .u-btn-2 {
    margin-top: 28px;
    margin-left: 95px;
  }

  .thankyou-page .u-text-5 {
    font-size: 1.125rem;
    margin-top: 45px;
    margin-left: 20px;
    margin-right: 20px;
  }

  .thankyou-page .u-text-6 {
    margin-left: 20px;
  }

  .thankyou-page .u-btn-3 {
    margin-top: 20px;
  }
}
